<template>
  <div class="cierre-inventarios mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Cierre de inventarios
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  :items="stocks"
                  v-model="stock"
                  outlined
                  rounded
                  dense
                  label="Tipo de inventario"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-select
                  :items="meses"
                  v-model="mes"
                  outlined
                  rounded
                  dense
                  label="Mes"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <v-select
                  :items="years"
                  v-model="year"
                  outlined
                  rounded
                  dense
                  label="Año"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-btn
                  class="float-right"
                  :dark="!loading"
                  :color="!loading ? 'black' : 'gray'"
                  :loading="loading"
                  :disabled="loading"
                  @click="generar()"
                  >Generar cierre</v-btn
                >
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-alert colored-border color="success" border="top">
                  <v-sheet>
                    <v-data-table
                      :headers="headers"
                      :footer-props="{ itemsPerPageText: 'Venta' }"
                      :items="cierres_venta"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Venta</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              @click="
                                $router.push(
                                  '/detalleCierre/' + item.fecha + '/' + 1
                                )
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver cierre</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.delete">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              class="ml-2"
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              @click="delete_cierre(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar cierre</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-alert>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-alert colored-border color="primary" border="top">
                  <v-sheet>
                    <v-data-table
                      :headers="headers"
                      :footer-props="{ itemsPerPageText: 'Consumo' }"
                      :items="cierres_consumo"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Consumo</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              @click="
                                $router.push(
                                  '/detalleCierre/' + item.fecha + '/' + 2
                                )
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver cierre</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.delete">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              class="ml-2"
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              @click="delete_cierre(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar cierre</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "cierre",
  data: () => ({
    loading: false,
    stock: 1,
    stocks: [
      { text: "Stock de venta", value: 1 },
      { text: "Stock de consumo", value: 2 },
    ],
    mes: parseInt(
      moment()
        .add(-1, "month")
        .format("M")
    ),
    year: moment().format("YYYY"),
    cierres: [],
    headers: [
      {
        text: "Fecha creación",
        value: "creado",
        align: "start",
      },
      {
        text: "Fecha de cierre",
        value: "format",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],
  }),
  methods: {
    loadCierres() {
      const body = {
        route: "/cierres",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.cierres = response.data.data;
        }
      });
    },
    generar() {
      this.loading = true;
      const body = {
        route: "/generate_cierre_inventario",
        data: {
          stock: this.stock,
          date: {
            inicio: `${this.year}-${moment(this.mes, "M").format(
              "MM"
            )}-${moment(this.mes, "M")
              .startOf("month")
              .format("DD")}`,
            fin: `${this.year}-${moment(this.mes, "M").format("MM")}-${moment(
              this.mes,
              "M"
            )
              .endOf("month")
              .format("DD")}`,
          },
          created: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadCierres();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    delete_cierre(item) {
      const body = {
        route: "/delete_cierre",
        data: {
          date: item.fecha,
          stock: item.stock,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadCierres();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
  },
  created() {
    this.loadCierres();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_cierres() {
      return this.cierres.map((c) => {
        return {
          creado: moment(c.creacion, "YYYY-MM-DD HH:mm:ss").format("ll h:mm A"),
          format: moment(c.fecha).format("MM/YYYY"),
          fecha: c.fecha,
          id: c.id,
          delete: false,
          stock: parseInt(c.stock),
        };
      });
    },
    cierres_venta() {
      const cierre = this.lista_cierres.filter((c) => {
        return c.stock == 1;
      });
      const max_index = Math.max(...cierre.map((c) => c.id));
      if (isFinite(max_index)) {
        const index = cierre.findIndex((element) => element.id == max_index);
        cierre[index].delete = true;
      }
      return cierre;
    },
    cierres_consumo() {
      const cierre = this.lista_cierres.filter((c) => {
        return c.stock == 2;
      });

      const max_index = Math.max(...cierre.map((c) => c.id));
      if (isFinite(max_index)) {
        const index = cierre.findIndex((element) => element.id == max_index);
        cierre[index].delete = true;
      }
      return cierre;
    },
    meses() {
      let meses = [];
      const capitalizar = (text) => {
        return text
          .toLowerCase()
          .trim()
          .split(" ")
          .map((t) => t[0].toUpperCase() + t.substr(1))
          .join(" ");
      };
      for (let i = 1; i <= 12; i++) {
        meses.push({
          text: capitalizar(moment(i, "M").format("MMMM")),
          value: i,
          disabled: false,
        });
      }
      return meses.map((mes) => {
        if (moment().format("YYYY") === this.year) {
          if (mes.value >= parseInt(moment().format("M"))) {
            mes.disabled = true;
          }
        }

        return mes;
      });
    },
    years() {
      let years = [];
      const start = moment([2015]);
      const end = moment();
      const diff = end.diff(start, "years");
      for (let i = 0; i <= diff; i++) {
        years.push(
          moment()
            .add(-i, "year")
            .format("YYYY")
        );
      }
      return years;
    },
  },
  watch: {
    year(val) {
      if (val == moment().format("YYYY")) {
        if (this.mes >= parseInt(moment().format("M"))) {
          this.mes = parseInt(
            moment()
              .add(-1, "month")
              .format("M")
          );
        }
      }
      return this.year;
    },
  },
};
</script>
