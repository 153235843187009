var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cierre-inventarios mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Cierre de inventarios ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.stocks,"outlined":"","rounded":"","dense":"","label":"Tipo de inventario"},model:{value:(_vm.stock),callback:function ($$v) {_vm.stock=$$v},expression:"stock"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.meses,"outlined":"","rounded":"","dense":"","label":"Mes"},model:{value:(_vm.mes),callback:function ($$v) {_vm.mes=$$v},expression:"mes"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.years,"outlined":"","rounded":"","dense":"","label":"Año"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.generar()}}},[_vm._v("Generar cierre")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-alert',{attrs:{"colored-border":"","color":"success","border":"top"}},[_c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":{ itemsPerPageText: 'Venta' },"items":_vm.cierres_venta},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Venta")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.$router.push(
                                '/detalleCierre/' + item.fecha + '/' + 1
                              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver cierre")])]),(item.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","fab":"","small":""},on:{"click":function($event){return _vm.delete_cierre(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar cierre")])]):_vm._e()]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-alert',{attrs:{"colored-border":"","color":"primary","border":"top"}},[_c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":{ itemsPerPageText: 'Consumo' },"items":_vm.cierres_consumo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Consumo")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.acciones",fn:function(ref){
                              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.$router.push(
                                '/detalleCierre/' + item.fecha + '/' + 2
                              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver cierre")])]),(item.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","fab":"","small":""},on:{"click":function($event){return _vm.delete_cierre(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar cierre")])]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }